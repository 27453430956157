import * as React from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import theme from '../src/theme';
import createEmotionCache from '../src/createEmotionCache';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Script from 'next/script'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;



  return (
    <CacheProvider value={emotionCache}>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-Y7BN4WDVPY"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-Y7BN4WDVPY');
          gtag('config', 'AW-10800748685');
        `}
      </Script>
      <Head>
        <title>Safetycon</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="shortcut icon" type="image/png" href="favicon.png" />
      </Head>
      <MuiThemeProvider theme={theme}>
        <EmotionThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...pageProps} />
        </EmotionThemeProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
}