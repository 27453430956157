import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme()

theme = createTheme(theme, {
  typography: {
    fontFamily: [
      'Roboto',
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '72pt',
      lineHeight: '1.11',
    },

    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '54pt',
      lineHeight: '1.11',
    },

    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '1.11',
    },

    h4: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '25pt',
      lineHeight: '1.11',
    },

    subtitle1: {
      fontSize: '19pt',
      fontFamily: 'Roboto',
      fontWeight: 300,
    },

    body1: {
      fontSize: '12px',
      fontFamily: 'Roboto',
      fontWeight: 300,
    },
  },

  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          fontFamily: 'Montserrat',
          fontWeight: 700,
          fontsize: "12px",
          height: "115px",
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 38,
        },
      },
    },
    MuiCard: { 
      styleOverrides: {
        root: { 
          // todo: tyylit tänne
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: { backgroundColor: 'black', width: "280px"}
      }
    }
  },

  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#ed4048',
    },
  },
});

export default responsiveFontSizes(theme, {
  factor: 3
});
