// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: 'https://6ed426c57f88495bb2379468eb5a133e@o876839.ingest.sentry.io/6042829',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    release: 'safetycon360-website-staging@1.3.0-staging.7',
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}